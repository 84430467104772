export const colorSchemas = {

    defaultSchema: {

        colors: {

            BACKGROUND: '#e8e8e8',
            EMPHASIS: '#525252',
            ALTER:'#d50e3c',
            DARK_TEXT: '#424242',
            LIGHT_TEXT: '#ffffff',
            BORDER: '#838383'

        }

    }

}