import {useContext, useState} from "react"
import * as emailValidator from 'email-validator'
import {schedulerFormConstants} from "../../utils/constants/components/schedulerFormConstants"
import {formErrorStates} from "../../utils/constants/shared/formErrorStates"
import {apiUrls} from "../../utils/constants/shared/apiUrls"
import {ModalProviderContext} from "../../providers/router/ModalProvider"
import modalStrings from "../../assets/strings/modalStrings.json"
import schedulerFormStrings from "../../assets/strings/schedulerFormStrings.json"

const useSchedulerForm = () => {

    const initialForm = {
        [schedulerFormConstants.schedulerNameField]: '',
        [schedulerFormConstants.schedulerEmailField]: '',
        [schedulerFormConstants.visitorNameField]: '',
        [schedulerFormConstants.visitorEmailField]: '',
        [schedulerFormConstants.dateField]: '',
        [schedulerFormConstants.timeField]: '',
        [schedulerFormConstants.reasonField]: '',
        [schedulerFormConstants.extraVisitorsField]: false,
        [schedulerFormConstants.extraVisitorsNumberField]: 0
    }

    const initialFormErrors = {
        [schedulerFormConstants.schedulerNameField]: formErrorStates.initialized,
        [schedulerFormConstants.schedulerEmailField]: formErrorStates.initialized,
        [schedulerFormConstants.visitorNameField]: formErrorStates.initialized,
        [schedulerFormConstants.visitorEmailField]: formErrorStates.initialized,
        [schedulerFormConstants.dateField]: formErrorStates.initialized,
        [schedulerFormConstants.timeField]: formErrorStates.initialized,
        [schedulerFormConstants.reasonField]: formErrorStates.initialized,
        [schedulerFormConstants.extraVisitorsField]: formErrorStates.noError,
        [schedulerFormConstants.extraVisitorsNumberField]: formErrorStates.noError
    }

    const[form, setForm] = useState(initialForm)
    const[formErrors, setFormErrors] = useState(initialFormErrors)
    const {buildModal} = useContext(ModalProviderContext)

    const handleChange = (e) => {

        const{value, name} = e.target

        if(name === schedulerFormConstants.extraVisitorsField){

            setForm({
                ...form,
                [name]: e.target.checked
            })

            if(e.target.checked){

                setFormErrors({
                    ...formErrors,
                    [schedulerFormConstants.extraVisitorsNumberField]: formErrorStates.initialized
                })

            }else{

                setFormErrors({
                    ...formErrors,
                    [schedulerFormConstants.extraVisitorsNumberField]: formErrorStates.noError
                })

            }

        }else if(name === schedulerFormConstants.schedulerEmailField ||
            name === schedulerFormConstants.visitorEmailField){

            setForm({
                ...form,
                [name]: value.toLowerCase()
            })

        }else{

            setForm({
                ...form,
                [name]: value
            })

        }

        handleBur(e)

    }

    const handleBur = (e) => {

        const{value, name} = e.target

        if(name === schedulerFormConstants.schedulerEmailField || name === schedulerFormConstants.visitorEmailField){

            if (!emailValidator.validate(value)) {

                setFormErrors({
                    ...formErrors,
                    [name]: formErrorStates.hasError
                })

            } else {

                let emailToCompare

                if(name === schedulerFormConstants.schedulerEmailField){

                    emailToCompare = form[schedulerFormConstants.visitorEmailField]

                }else{

                    emailToCompare = form[schedulerFormConstants.schedulerEmailField]

                }

                if(value.toLowerCase() === emailToCompare){

                    setFormErrors({
                        ...formErrors,
                        [name]: formErrorStates.duplicatedEmailError
                    })

                }else{

                    setFormErrors({
                        ...formErrors,
                        [name]: formErrorStates.noError
                    })

                }


            }

        }else{

            if(value.trim()){

                setFormErrors({
                    ...formErrors,
                    [name]: formErrorStates.noError
                })

            }else{

                setFormErrors({
                    ...formErrors,
                    [name]: formErrorStates.hasError
                })

            }

        }

    }

    const validateForm = () => {

        for (const element in formErrors) {

            if(formErrors[element] !== formErrorStates.noError){

                if(formErrors[element] === formErrorStates.duplicatedEmailError){

                    buildModal(modalStrings.DUPLICATED_EMAIL, modalStrings.BUTTON_CLOSE)

                }else{

                    buildModal(modalStrings.MISSING_FIELD, modalStrings.BUTTON_CLOSE)

                }

                return false

            }

        }

        return true

    }

    const handleSubmit = () => {

        const validated = validateForm()

        if(validated) {

            buildModal(modalStrings.LOADING, modalStrings.BUTTON_CLOSE)
            const controller = new AbortController()
            setTimeout(() => controller.abort(), 15000)

            fetch(process.env.REACT_APP_API_URL + apiUrls.scheduler, {

                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(form),
                signal: controller.signal

            }).then(response => {

                switch (response.status) {

                    case 200:
                        buildModal(schedulerFormStrings.MODAL_SCHEDULED, modalStrings.BUTTON_CLOSE)
                        setForm(initialForm)
                        setFormErrors(initialFormErrors)
                        break

                    case 500:
                        buildModal(schedulerFormStrings.MODAL_SERVER_ERROR, modalStrings.BUTTON_CLOSE)
                        break

                    case 503:
                        buildModal(schedulerFormStrings.MODAL_SERVICE_UNAVAILABLE, modalStrings.BUTTON_CLOSE)
                        break

                    default:
                        buildModal(schedulerFormStrings.MODAL_UNKNOWN_ERROR, modalStrings.BUTTON_CLOSE)
                        break

                }

            }).catch(e => {

                console.log(e)
                buildModal(modalStrings.CONNECTION_TIME_OUT, modalStrings.BUTTON_CLOSE)

            })

        }

    }

    return{
        form,
        formErrors,
        handleChange,
        handleBur,
        handleSubmit
    }

}

export default useSchedulerForm