import {fontValues} from "../../../assets/values/fontValues"
import styled from "styled-components"

export const Label = styled.p `

  margin: 0;
  font-family: ${fontValues.FONT_FAMILY};
  font-size: ${fontValues.TEXT_SIZE};
  color: ${({theme}) => theme.colors.DARK_TEXT};

`