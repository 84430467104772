import React, {Fragment} from "react"
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import {ThemeProvider} from "styled-components"
import {RouterStyles} from "../styles/pages/router"
import {routes} from "../utils/constants/shared/routes"
import {colorSchemas} from "../assets/values/colorSchemas"
import ModalProvider from "../providers/router/ModalProvider"

import NotFound from "./notFound/NotFound"
import Scheduler from "./scheduler/Scheduler"

const Container = () => {

    const router = createBrowserRouter([
        {
            path: routes.MAIN,
            element: <Scheduler/>,
            errorElement: <NotFound/>
        },
        {
            path: routes.SCHEDULER,
            element: <Scheduler/>
        }
    ])

    return (

        <Fragment>

            <ThemeProvider theme={colorSchemas.defaultSchema}>

                <RouterStyles.Container>

                    <ModalProvider>

                        <RouterProvider router={router}/>

                    </ModalProvider>

                </RouterStyles.Container>

            </ThemeProvider>

        </Fragment>

    )

}

export default Container