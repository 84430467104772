import {Container} from "./Container"
import {Input} from "./Input"
import {FieldsContainer} from "./FieldsContainer"
import {Field} from "./Field"
import {Label} from "./Label"
import {Title} from "./Title"
import {Button} from "./Button"
import {Select} from "./Select"
import {CheckBox} from "./CheckBox"

export const SchedulerFormStyles = {

    Container,
    Input,
    FieldsContainer,
    Field,
    Label,
    Title,
    Button,
    Select,
    CheckBox

}