import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './assets/fonts/articulat.otf'
import Router from "./pages/Router"

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <Router/>
  </React.StrictMode>
)