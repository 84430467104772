import {Fragment} from "react"
import schedulerFormStrings from '../../assets/strings/schedulerFormStrings.json'
import useSchedulerForm from "./useSchedulerForm"
import {schedulerFormConstants} from "../../utils/constants/components/schedulerFormConstants"

import {SchedulerFormStyles} from "../../styles/components/schedulerForm"
import {formErrorStates} from "../../utils/constants/shared/formErrorStates"

const SchedulerForm = () => {

    const {
        form,
        formErrors,
        handleChange,
        handleBur,
        handleSubmit
    } = useSchedulerForm()

    return(

        <Fragment>

            <SchedulerFormStyles.Container>

                <SchedulerFormStyles.FieldsContainer>

                    <SchedulerFormStyles.Title>
                        {schedulerFormStrings.TITLE}
                    </SchedulerFormStyles.Title>

                    <SchedulerFormStyles.Field $isVisible={true}>

                        <SchedulerFormStyles.Label>
                            {schedulerFormStrings.SCHEDULER_NAME}
                        </SchedulerFormStyles.Label>

                        <SchedulerFormStyles.Input onChange={handleChange} onBlur={handleBur}
                        $hasError={formErrors[schedulerFormConstants.schedulerNameField]
                        === formErrorStates.hasError} autoComplete="off"
                        value={form[schedulerFormConstants.schedulerNameField]}
                        name={schedulerFormConstants.schedulerNameField} type='text'/>

                    </SchedulerFormStyles.Field>

                    <SchedulerFormStyles.Field $isVisible={true}>

                        <SchedulerFormStyles.Label>
                            {schedulerFormStrings.SCHEDULER_EMAIL}
                        </SchedulerFormStyles.Label>

                        <SchedulerFormStyles.Input onChange={handleChange} onBlur={handleBur}
                        $hasError={formErrors[schedulerFormConstants.schedulerEmailField]
                        === formErrorStates.hasError || formErrors[schedulerFormConstants.schedulerEmailField]
                        === formErrorStates.duplicatedEmailError} autoComplete="off"
                        value={form[schedulerFormConstants.schedulerEmailField]}
                        name={schedulerFormConstants.schedulerEmailField} type='text'/>

                    </SchedulerFormStyles.Field>

                    <SchedulerFormStyles.Field $isVisible={true}>

                        <SchedulerFormStyles.Label>
                            {schedulerFormStrings.VISITOR_NAME}
                        </SchedulerFormStyles.Label>

                        <SchedulerFormStyles.Input onChange={handleChange} onBlur={handleBur}
                        $hasError={formErrors[schedulerFormConstants.visitorNameField]
                        === formErrorStates.hasError} autoComplete="off"
                        value={form[schedulerFormConstants.visitorNameField]}
                        name={schedulerFormConstants.visitorNameField} type='text'/>

                    </SchedulerFormStyles.Field>

                    <SchedulerFormStyles.Field $isVisible={true}>

                        <SchedulerFormStyles.Label>
                            {schedulerFormStrings.VISITOR_EMAIL}
                        </SchedulerFormStyles.Label>

                        <SchedulerFormStyles.Input onChange={handleChange} onBlur={handleBur}
                        $hasError={formErrors[schedulerFormConstants.visitorEmailField]
                        === formErrorStates.hasError || formErrors[schedulerFormConstants.visitorEmailField]
                        === formErrorStates.duplicatedEmailError} autoComplete="off"
                        value={form[schedulerFormConstants.visitorEmailField]}
                        name={schedulerFormConstants.visitorEmailField} type='email'/>

                    </SchedulerFormStyles.Field>

                    <SchedulerFormStyles.Field $isVisible={true}>

                        <SchedulerFormStyles.Label>
                            {schedulerFormStrings.DATE}
                        </SchedulerFormStyles.Label>

                        <SchedulerFormStyles.Input onChange={handleChange} onBlur={handleBur}
                        $hasError={formErrors[schedulerFormConstants.dateField] === formErrorStates.hasError}
                        value={form[schedulerFormConstants.dateField]}
                        autoComplete="off" name={schedulerFormConstants.dateField} type='date'/>

                    </SchedulerFormStyles.Field>

                    <SchedulerFormStyles.Field $isVisible={true}>

                        <SchedulerFormStyles.Label>
                            {schedulerFormStrings.TIME}
                        </SchedulerFormStyles.Label>

                        <SchedulerFormStyles.Input onChange={handleChange} onBlur={handleBur}
                        $hasError={formErrors[schedulerFormConstants.timeField] === formErrorStates.hasError}
                        value={form[schedulerFormConstants.timeField]}
                        name={schedulerFormConstants.timeField} type='time' autoComplete="off"/>

                    </SchedulerFormStyles.Field>

                    <SchedulerFormStyles.Field $isVisible={true}>

                        <SchedulerFormStyles.Label>
                            {schedulerFormStrings.REASON}
                        </SchedulerFormStyles.Label>

                        <SchedulerFormStyles.Select onChange={handleChange} onBlur={handleBur}
                        $hasError={formErrors[schedulerFormConstants.reasonField] === formErrorStates.hasError}
                        value={form[schedulerFormConstants.reasonField]}
                        name={schedulerFormConstants.reasonField}>

                            <option value="">
                                {schedulerFormStrings.SELECT_DEFAULT}
                            </option>

                            <option value={schedulerFormStrings.SELECT_ADMIN}>
                                {schedulerFormStrings.SELECT_ADMIN}
                            </option>

                            <option value={schedulerFormStrings.SELECT_PASTOR_OFFICE}>
                                {schedulerFormStrings.SELECT_PASTOR_OFFICE}
                            </option>

                            <option value={schedulerFormStrings.SELECT_DISCIPLESHIP_OFFICE}>
                                {schedulerFormStrings.SELECT_DISCIPLESHIP_OFFICE}
                            </option>

                            <option value={schedulerFormStrings.SELECT_EDUCATIONAL_AREA}>
                                {schedulerFormStrings.SELECT_EDUCATIONAL_AREA}
                            </option>

                            <option value={schedulerFormStrings.SELECT_AUDITORIUMS}>
                                {schedulerFormStrings.SELECT_AUDITORIUMS}
                            </option>

                            <option value={schedulerFormStrings.SELECT_FORUMS}>
                                {schedulerFormStrings.SELECT_FORUMS}
                            </option>

A                            <option value={schedulerFormStrings.SELECT_STUDIO}>
                                {schedulerFormStrings.SELECT_STUDIO}
                            </option>

                            <option value={schedulerFormStrings.SELECT_PRACTICE_ROOM}>
                                {schedulerFormStrings.SELECT_PRACTICE_ROOM}
                            </option>

                        </SchedulerFormStyles.Select>

                    </SchedulerFormStyles.Field>

                    <SchedulerFormStyles.Field $isVisible={true}>

                        <SchedulerFormStyles.Label>
                            {schedulerFormStrings.EXTRA_VISITORS}
                        </SchedulerFormStyles.Label>

                        <SchedulerFormStyles.CheckBox onChange={handleChange} onBlur={handleBur}
                        $hasError={formErrors[schedulerFormConstants.extraVisitorsField]
                        === formErrorStates.hasError}
                        checked={form[schedulerFormConstants.extraVisitorsField]}
                        name={schedulerFormConstants.extraVisitorsField} type='checkbox'/>

                    </SchedulerFormStyles.Field>

                    <SchedulerFormStyles.Field $isVisible={form[schedulerFormConstants.extraVisitorsField]}>

                        <SchedulerFormStyles.Label>
                            {schedulerFormStrings.EXTRA_VISITORS_NUMBER}
                        </SchedulerFormStyles.Label>

                        <SchedulerFormStyles.Input onChange={handleChange} onBlur={handleBur}
                        $hasError={formErrors[schedulerFormConstants.extraVisitorsNumberField]
                        === formErrorStates.hasError} autoComplete="off"
                        value={form[schedulerFormConstants.extraVisitorsNumberField]}
                        name={schedulerFormConstants.extraVisitorsNumberField} type='number'/>

                    </SchedulerFormStyles.Field>

                    <SchedulerFormStyles.Button onClick={handleSubmit}>
                        {schedulerFormStrings.SEND_BUTTON}
                    </SchedulerFormStyles.Button>

                </SchedulerFormStyles.FieldsContainer>

            </SchedulerFormStyles.Container>

        </Fragment>

    )

}

export default SchedulerForm