import styled from "styled-components"

export const Button = styled.button`

    border: none;
    border-radius: 3px;
    width: fit-content;
    min-width: 120px;
    height: 50px;
    justify-self: center;
    background-color: ${({theme}) => theme.colors.EMPHASIS};
    color: ${({theme}) => theme.colors.LIGHT_TEXT};

`