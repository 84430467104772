import styled from "styled-components"
import {mediaQueriesSizes} from "../../../utils/constants/shared/mediaQueriesSizes"

export const FieldsContainer = styled.div `

    width: 70%;
    height: 80%;
    min-height: 700px;
    display: grid;
    gap: 10px;
    justify-self: center;
    align-self: center;
    align-items: center;
    margin: 30px 0;

    @media (max-width: ${mediaQueriesSizes.WIDTH_FOR_MOBILES}){
      width: 80%;
      height: auto;
    }

`