import styled, {keyframes} from "styled-components"

const crescendo = keyframes `
  
    from{
        transform: scale(.1);
    }

    to{
        transform: scale(1);
    }
    
`

export const Card = styled.div `

  width: 300px;
  height: 300px;
  display: grid;
  grid-template-rows: 65% 35%;
  align-items: center;
  justify-items: center;
  border-radius: 10px;
  padding: 20px 10px;
  animation: ${crescendo} .2s alternate;

  background-color: ${({theme}) => theme.colors.BACKGROUND};
  border: 1px solid ${({theme}) => theme.colors.BORDER};

`