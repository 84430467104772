import React, {Fragment} from "react"

import SchedulerForm from "../../components/schedulerForm/SchedulerForm"
import {SchedulerStyles} from "../../styles/pages/scheduler"

const Scheduler = () => {

    return(

        <Fragment>

            <SchedulerStyles.Container>

                <SchedulerForm/>

            </SchedulerStyles.Container>

        </Fragment>

    )

}

export default Scheduler