import styled from "styled-components"
import {fontValues} from "../../../assets/values/fontValues"

export const Input = styled.input`

    width: calc(100% - 2px);
    height: 28px;
    padding: 0;
    margin: 0;
    border-radius: 2px;
    background-color: transparent;
    font-family: ${fontValues.FONT_FAMILY};
    font-size: ${fontValues.TEXT_SIZE};
    color: ${({theme}) => theme.colors.DARK_TEXT};
    border: 1px solid ${props => props.$hasError ?
          ({theme}) => theme.colors.ALTER : ({theme}) => theme.colors.BORDER};

    &:focus{
      outline: none;
    }
    
    &:-webkit-autofill{
      -webkit-text-fill-color: ${({theme}) => theme.colors.DARK_TEXT};
      transition: background-color 0s 50000s;
    }

`