import styled from "styled-components"
import {fontValues} from "../../../assets/values/fontValues"

export const Title = styled.h1`

    margin: 0 0 20px 0;
    justify-self: center;
    font-family: ${fontValues.FONT_FAMILY};
    font-size: ${fontValues.TITLE_SIZE};
    color: ${({theme}) => theme.colors.DARK_TEXT};

`