import {createContext, useState} from "react"
import {createPortal} from "react-dom"
import NotificationsModal from "../../modals/notificationsModal/NotificationsModal"

export const ModalProviderContext = createContext(null)

const ModalProvider = ({children}) => {

    const [modal, setModal] = useState(null)

    /**
     * @param {string} text - main text of modal
     * @param {string} buttonText - button text of modal
     * */
    const buildModal = (text, buttonText,) => {

        setModal(<NotificationsModal text={text} buttonText={buttonText}/>)

    }

    const closeModal = () => {

        setModal(null)

    }

    const value = {
        buildModal,
        closeModal
    }

    return(

        <ModalProviderContext.Provider value={value}>
            {children}
            {createPortal(modal, document.body)}
        </ModalProviderContext.Provider>

    )

}

export default ModalProvider