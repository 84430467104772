import styled from "styled-components"
import {fontValues} from "../../assets/values/fontValues"

export const Text = styled.p `

    margin: 0;
    justify-self: center;
    font-family: ${fontValues.FONT_FAMILY};
    font-size: ${fontValues.TEXT_SIZE};
    text-align: center;
    color: ${({theme}) => theme.colors.DARK_TEXT};
    
`