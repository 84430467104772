export const schedulerFormConstants = {
    schedulerNameField: 'schedulerNameField',
    schedulerEmailField: 'schedulerEmailField',
    visitorNameField: 'visitorNameField',
    visitorEmailField: 'visitorEmailField',
    dateField: 'dateField',
    timeField: 'timeField',
    reasonField: 'reasonField',
    extraVisitorsField: 'extraVisitorsField',
    extraVisitorsNumberField: 'extraVisitorsNumberField',
}