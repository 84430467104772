import { useRouteError } from "react-router-dom"
import {NotFoundStyles} from "../../styles/pages/notFound"

const NotFound = () => {

    const error = useRouteError()

    return (

        <NotFoundStyles.Container>

            <h1>Oops!</h1>
            <p>An error occurred</p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>

        </NotFoundStyles.Container>

    )

}

export default NotFound