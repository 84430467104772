import styled from "styled-components"

export const Field = styled.div `

    width: 100%;
    height: 50px;
    display: ${props => props.$isVisible ? 'grid' : 'none'};
    grid-template-rows: 50% 50%;
    align-items: center;

`