import {Fragment} from "react"
import {NotificationsModalStyles} from "../../styles/modals/notificationsModal"
import useNotificationsModal from "./useNotificationsModal"

import {Button} from "../../styles/shared/Button"
import {Text} from "../../styles/shared/Text"

const NotificationsModal = ({text, buttonText}) => {

    const {
        handleClick
    } = useNotificationsModal()

    return(

        <Fragment>

            <NotificationsModalStyles.Container>

                <NotificationsModalStyles.Card>

                    <Text>{text}</Text>

                    <Button onClick={handleClick}>{buttonText}</Button>

                </NotificationsModalStyles.Card>

            </NotificationsModalStyles.Container>

        </Fragment>

    )

}

export default NotificationsModal