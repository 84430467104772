import styled from "styled-components"

export const CheckBox = styled.input`

    width: 25px;
    height: 25px;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    justify-self: left;
    background-color: transparent;
    color: ${({theme}) => theme.colors.DARK_TEXT};
    border: 1px solid ${props => props.$hasError ?
    ({theme}) => theme.colors.ALTER : ({theme}) => theme.colors.BORDER};

    &:focus{
      outline: none;
    }
    
    &:-webkit-autofill{
      -webkit-text-fill-color: ${({theme}) => theme.colors.DARK_TEXT};
      transition: background-color 0s 50000s;
    }

`