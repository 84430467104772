import styled from "styled-components"
import {mediaQueriesSizes} from "../../../utils/constants/shared/mediaQueriesSizes"

export const Container = styled.div `
  
    width: 450px;
    height: 80%;
    display: grid;
    justify-self: center;
    align-self: center;
    border: 2px solid ${({theme}) => theme.colors.BORDER};
    border-radius: 5px;
    overflow: auto;
  
    @media (max-width: ${mediaQueriesSizes.WIDTH_FOR_MOBILES}){
      
      width: 100%;
      height: 100%;
      min-height: 600px;
      border: none;
      
    }

`