import {useContext} from "react"
import {ModalProviderContext} from "../../providers/router/ModalProvider"

const useNotificationsModal = () => {

    const {closeModal} = useContext(ModalProviderContext)

    const handleClick = () => {

        closeModal()

    }

    return{
        handleClick
    }

}

export default useNotificationsModal